import React from 'react';
import { useTranslation } from 'react-i18next';
import { MDBCardBody, MDBCol, MDBContainer, MDBMask, MDBRow, MDBView } from 'mdbreact';

import charteringImg from '../../resources/images/cards/chartering.jpg';
import operationImg from '../../resources/images/cards/operations.jpg';
import researchImg from '../../resources/images/cards/research.jpg';

function Services() {
  const { t } = useTranslation();

  return (
    <MDBContainer>
      <h2 className='h1-responsive font-weight-bold mb-5'>{ t('services.title') }</h2>
      <MDBRow className='text-center'>
        <MDBCol lg='4' md='12' className='mb-lg-0 mb-4'>
          <MDBView className='overlay rounded z-depth-1' waves>
            <img src={ charteringImg } alt='Chartering' className='img-fluid' />
            <MDBMask overlay='white-slight' />
          </MDBView>
          <MDBCardBody className='pb-0'>
            <h4 className='font-weight-bold my-3'>{ t('services.chartering.title') }</h4>
            <p align='justify'>{ t('services.chartering.body') }</p>
          </MDBCardBody>
        </MDBCol>

        <MDBCol lg='4' md='12' className='mb-lg-0 mb-4'>
          <MDBView className='overlay rounded z-depth-1' waves>
            <img src={operationImg} alt='Chartering' className='img-fluid' />
            <MDBMask overlay='white-slight' />
          </MDBView>
          <MDBCardBody className='pb-0'>
            <h4 className='font-weight-bold my-3'>{ t('services.operations.title') }</h4>
            <p align='justify'>{ t('services.operations.body') }</p>
          </MDBCardBody>
        </MDBCol>

        <MDBCol lg='4' md='12' className='mb-lg-0 mb-4'>
          <MDBView className='overlay rounded z-depth-1' waves>
            <img src={researchImg} alt='Chartering' className='img-fluid' />
            <MDBMask overlay='white-slight' />
          </MDBView>
          <MDBCardBody className='pb-0'>
            <h4 className='font-weight-bold my-3'>{ t('services.research.title') }</h4>
            <p align='justify'>{ t('services.research.body') }</p>
          </MDBCardBody>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Services;
