import React from 'react';
import { useTranslation } from 'react-i18next';
import { MDBAnimation, MDBContainer, MDBMask, MDBRow, MDBCol, MDBView } from 'mdbreact';

import Contact from './Contact';
import Services from './Services';
import StreakCover from './StreakCover';

import cover1 from '../../resources/images/cover/ship-1.jpg';
import cover2 from '../../resources/images/cover/ship-3.jpg';

function Cover() {
  const { t } = useTranslation();
  const images = [cover1, cover2];

  return (
    <>
      <section id='cover'>
        <MDBView src={images[Math.floor(Math.random() * images.length)]}>
          <MDBMask className='rgba-black-light d-flex justify-content-center align-items-center'>
            <MDBContainer className='cover'>
              <MDBRow>
                <MDBCol md='12' className='mb-4 white-text text-center'>
                  <MDBAnimation type='slideInDown'>
                    <h1 className='display-3 font-weight-bold text-uppercase white-text mb-0 pt-md-5 pt-5'>{ t('title') }</h1>
                    <hr className='hr-light my-2 w-75' />
                    <h3 className='subtext-header mt-2 mb-4'>{ t('sub-title') }</h3>
                  </MDBAnimation>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </section>

      <section id='about' className='py-5 mt-5'>
        <MDBContainer>
          <h2 className='h1-responsive font-weight-bold text-center'>{ t('about-us.title') }</h2>
          <p className='lead mb-5' align='justify'>{ t('about-us.body') }</p>
        </MDBContainer>
      </section>

      <section id='services' className='text-center py-5' style={{ backgroundColor: '#eee' }}>
        <Services />
      </section>

      <section id='streak-cover'>
        <StreakCover />
      </section>

      <section id='contact'>
        <Contact />
      </section>
    </>
  );
}

export default Cover;
