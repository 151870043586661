import React from 'react';
import { useTranslation } from 'react-i18next';
import { MDBCol, MDBIcon, MDBRow, MDBBtn } from 'mdbreact';

function Contact() {
  const { t } = useTranslation();

  return (
    <div className="text-center mb-5">
      <h2 className="h1-responsive font-weight-bold my-5">
        {t('contact')}
      </h2>
      <MDBRow className="mr-0 ml-0">

        <MDBCol md="4">
          <MDBBtn
            color="blue darken-1"
            floating
            href="https://www.google.com/maps/place/372+Danbury+Rd,+Wilton,+CT+06897/@41.2071639,-73.4303499,20.01z/data=!4m5!3m4!1s0x89e802e80c17b993:0xd1780b751ab8ffa9!8m2!3d41.2070529!4d-73.4302408"
            target="_blank"
          >
            <MDBIcon icon="fas fa-map-marker-alt" size="2x" />
          </MDBBtn>
          <p className="lead text-center">372 Danbury Road Ste 185</p>
          <p className="lead text-center mb-0">Wilton, CT 06897</p>
        </MDBCol>

        <MDBCol md="4">
          <MDBBtn
            color="blue darken-1"
            floating
            href="tel:1-203-202-7490"
          >
            <MDBIcon icon="fas fa-phone" size="2x" />
          </MDBBtn>
          <p className="lead text-center">(203) 202 7490</p>
          <p className="lead text-center mb-md-0">Mon - Fri 7:00 - 22:00</p>
        </MDBCol>

        <MDBCol md="4">
          <MDBBtn
            color="blue darken-1"
            floating
            href="mailto:chartering@truenorthchartering.com"
          >
            <MDBIcon icon="fas fa-envelope" size="2x" />
          </MDBBtn>
          <p className="lead text-center">chartering@truenorthchartering.com</p>
          <p className="lead text-center mb-0">operations@truenorthchartering.com</p>
        </MDBCol>

      </MDBRow>
    </div>
  );
}

export default Contact;
