import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import cookies from 'js-cookie';
import {
  MDBCollapse,
  MDBContainer,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler
} from 'mdbreact';
import 'flag-icons/css/flag-icons.min.css';

import logo from '../../resources/images/brand/Logo@2x.png';

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'us',
  },
  {
    code: 'es',
    name: 'Español',
    country_code: 'es',
  },
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
];

function Navbar({ history }) {
  const [collapse, setCollapse] = useState(false);

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  const path = history.location.pathname;

  useEffect(() => {
    console.log('Setting page language.')
    document.title = t('title')
  }, [currentLanguage, t]);

  return (
    <MDBNavbar
      color="truenorth-gradient"
      dark
      expand="md"
      fixed="top"
      scrolling
      transparent={path === '/'}
    >
      <MDBContainer>
        <MDBNavbarBrand>
          <img src={logo} alt="True North Chartering" width="48" />
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={() => setCollapse(!collapse)} />
        <MDBCollapse isOpen={collapse} navbar>
          <MDBNavbarNav left>
            <MDBNavItem active={path === '/'}>
              <MDBNavLink to="/">{ t('links.home') }</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem active={path === '/team'}>
              <MDBNavLink to="/team">{ t('links.team') }</MDBNavLink>
            </MDBNavItem>
          </MDBNavbarNav>

          <MDBNavbarNav right>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <MDBIcon icon="globe" />
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-primary">
                  {languages.map(({ code, name, country_code }) => (
                    <MDBDropdownItem
                      href="#"
                      key={country_code}
                      onClick={() => { i18next.changeLanguage(code) }}
                      active={currentLanguageCode === code}
                    >
                      <span className={`fi fi-${ country_code }`}></span> { name }
                    </MDBDropdownItem>
                  )) }
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}

export default withRouter(Navbar);
