import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Home from './Home'
import Navbar from './Navbar'
import Team from './Team'
import Footer from './Footer'

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Switch>
        <Route exact path="/" component={ Home } />
        <Route exact path="/team" component={ Team } />
      </Switch>
      <Footer />
    </BrowserRouter>
  )
}

export default App
