import React from 'react'
import { MDBCardBody, MDBCardTitle, MDBListGroup, MDBListGroupItem } from 'mdbreact'
import { formatPhoneNumber } from 'react-phone-number-input'

function Bio({ bio }) {
  return (
    <MDBCardBody className="px-5 mt-3" cascade>
      <MDBCardTitle>{bio.firstName} {bio.lastName}</MDBCardTitle>
      {bio.title &&
        <MDBListGroupItem className="text-bold">{bio.title}</MDBListGroupItem>
      }
      <MDBListGroup>
        <MDBListGroupItem>{formatPhoneNumber(`+1${bio.mobile}`)}</MDBListGroupItem>
        <MDBListGroupItem>{bio.email}</MDBListGroupItem>
        <MDBListGroupItem>{bio.emailGroup}</MDBListGroupItem>
      </MDBListGroup>
      <p className="lead lead-bio" align="justify">
        {bio.bio}
      </p>
    </MDBCardBody>
  )
}

export default Bio
