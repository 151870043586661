import React from 'react';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { MDBContainer, MDBCard, MDBCardImage } from 'mdbreact';

import Bio from './Bio';

import en_bios from '../../data/locales/en/bios';
import es_bios from '../../data/locales/es/bios';
import fr_bios from '../../data/locales/fr/bios';


function Team() {
  const lang = cookies.get('i18next') || 'en';
  var bios = en_bios;

  switch (lang) {
    case 'es':
      bios = es_bios
      break;

    case 'fr':
      bios = fr_bios
      break;

    default:
      bios = en_bios
      break;
  }

  const { t } = useTranslation();

  return (
    <MDBContainer className="mb-5" style={ { marginTop: '10rem' } }>
      {/* CPP DESK */ }
      <MDBCard style={ { marginTop: '5rem' } } narrow>
        <MDBCardImage className="view view-cascade gradient-card-header blue darken-1" cascade tag="div">
          <h2 className="card-header-title">{ t('bio.cpp') }</h2>
        </MDBCardImage>
        { bios && bios.filter(bio => bio.desk === 'cpp').map((bio, index) => {
          return (
            <Bio bio={ bio } key={ bio && bio.id ? bio.id : index } />
          )
        }) }
      </MDBCard>

      {/* DPP DESK */ }
      <MDBCard style={ { marginTop: '5rem' } } narrow>
        <MDBCardImage className="view view-cascade gradient-card-header blue darken-1" cascade tag="div">
          <h2 className="card-header-title">{ t('bio.dpp') }</h2>
        </MDBCardImage>
        { bios && bios.filter(bio => bio.desk === 'dpp').map((bio, index) => {
          return (
            <Bio bio={ bio } key={ bio && bio.id ? bio.id : index } />
          )
        }) }
      </MDBCard>

      {/* PROJECTS DESK */ }
      <MDBCard style={ { marginTop: '5rem' } } narrow>
        <MDBCardImage className="view view-cascade gradient-card-header blue darken-1" cascade tag="div">
          <h2 className="card-header-title">{ t('bio.projects') }</h2>
        </MDBCardImage>
        { bios && bios.filter(bio => bio.desk === 'projects').map((bio, index) => {
          return (
            <Bio bio={ bio } key={ bio && bio.id ? bio.id : index } />
          )
        }) }
      </MDBCard>

      {/* POST FIXTURE DESK */ }
      <MDBCard style={ { marginTop: '5rem' } } narrow>
        <MDBCardImage className="view view-cascade gradient-card-header blue darken-1" cascade tag="div">
          <h2 className="card-header-title">{ t('bio.operations') }</h2>
        </MDBCardImage>
        { bios && bios.filter(bio => bio.desk === 'ops').map((bio, index) => {
          return (
            <Bio bio={ bio } key={ bio && bio.id ? bio.id : index } />
          )
        }) }
      </MDBCard>

      {/* CLAIMS DESK */ }
      <MDBCard style={ { marginTop: '5rem' } } narrow>
        <MDBCardImage className="view view-cascade gradient-card-header blue darken-1" cascade tag="div">
          <h2 className="card-header-title">{ t('bio.claims') }</h2>
        </MDBCardImage>
        { bios && bios.filter(bio => bio.desk === 'claims').map((bio, index) => {
          return (
            <Bio bio={ bio } key={ bio && bio.id ? bio.id : index } />
          )
        }) }
      </MDBCard>

    </MDBContainer>
  )
}

export default Team
