import React from 'react';
import { useTranslation } from 'react-i18next';
import { MDBCol, MDBContainer, MDBMask, MDBRow, MDBView } from 'mdbreact';

import streakImg from '../../resources/images/streak/ship-streak-1.jpg';

function StreakCover() {
  const { t } = useTranslation();

  return (
    <MDBView src={streakImg} className="streak-view">
      <MDBMask className="text-white text-center d-flex align-items-center rgba-black-light py-5 px-4">
        <MDBContainer className="cover">
          <MDBRow>
            <MDBCol md="12" className="mb-4 white-text text-center">
              <h2 className="h2-responsive">
                <i className="fas fa-quote-left" aria-hidden="true"></i>
                { t('streak.quote') }
                <i className="fas fa-quote-right" aria-hidden="true"></i>
              </h2>
              <h5 className="text-center font-italic mb-0">~ { t('streak.author') }</h5>
              <div className="text-center mt-4">
                <a href="/team" className="btn btn-outline-white wow fadeIn " data-offset="100"
                  data-wow-delay="0.2s">{ t('streak.action-btn') }</a>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBMask>
    </MDBView>
  );
}

export default StreakCover;
