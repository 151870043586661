import React from 'react';
import { useTranslation } from 'react-i18next';
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from 'mdbreact';

import Privacy from './Privacy';

function Footer() {
  const { t } = useTranslation();

  return (
    <MDBFooter color='blue darken-1' className='font-small pt-4 mt-5'>
      <MDBContainer className='text-center text-md-left'>
        <MDBRow className='pb-3'>
          <MDBCol md='4' sm='12'>
            <p className='back-to-top'>
              <a href='#!' onClick={() => window.scrollTo(0, 0)}>
                { t('top-btn') }
              </a>
            </p>
          </MDBCol>
          <MDBCol md='4' sm='12' className='privacy-wrapper'>
            {/*<a href="#!" data-toggle="modal" data-target="#privacyModalOpen"><p className="privacy-text">Privacy</p></a>*/}
            <Privacy />
          </MDBCol>
          <MDBCol md='4' sm='12' className='social-media-wrapper'>
            <a href='https://www.linkedin.com/company/true-north-chartering/' target='_blank' rel='noopener noreferrer'>
              <i className='fab fa-linkedin fa-3x'></i>
            </a>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className='footer-copyright text-center py-3'>
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright:{' '}
          <a href='https://truenorthchartering.com'> True North Chartering LLC </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default Footer;
