import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';

import logo from '../../resources/images/brand/og-logo@2x.png';

function Privacy() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <MDBContainer>
      <a href="#!" onClick={() => setOpen(!open)}><p className="privacy-text">{ t('links.privacy') }</p></a>
      <MDBModal className="modal-notify modal-danger text-white mt-5" isOpen={open} toggle={() => setOpen(!open)}>
        <MDBModalHeader className="text-center" titleClass="w-100" tag="p">
          { t('privacy.title') }
        </MDBModalHeader>
        <MDBModalBody className="text-center">
          <div className="text-center">
            <img src={logo} width="100" alt="True North Chartering" />
            <p className="mt-3" align="justify">
              {t('privacy.p1')}
              <br /><br />
              {t('privacy.p2')}
              <br /><br />
              {t('privacy.p3')}
              <br /><br />
              {t('privacy.p4')}
            </p>
          </div>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
}

export default Privacy;
